/**
 * Minified by jsDelivr using clean-css v4.1.11.
 * Original file: /gh/coliff/bootstrap-ie8@4.1.3/css/bootstrap-ie9.css
 * 
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
 .card-group .card,.col-1,.col-10,.col-11,.col-12,.col-2,.col-3,.col-4,.col-5,.col-6,.col-7,.col-8,.col-9,.col-lg-1,.col-lg-10,.col-lg-11,.col-lg-12,.col-lg-2,.col-lg-3,.col-lg-4,.col-lg-5,.col-lg-6,.col-lg-7,.col-lg-8,.col-lg-9,.col-md-1,.col-md-10,.col-md-11,.col-md-12,.col-md-2,.col-md-3,.col-md-4,.col-md-5,.col-md-6,.col-md-7,.col-md-8,.col-md-9,.col-sm-1,.col-sm-10,.col-sm-11,.col-sm-12,.col-sm-2,.col-sm-3,.col-sm-4,.col-sm-5,.col-sm-6,.col-sm-7,.col-sm-8,.col-sm-9,.col-xl-1,.col-xl-10,.col-xl-11,.col-xl-12,.col-xl-2,.col-xl-3,.col-xl-4,.col-xl-5,.col-xl-6,.col-xl-7,.col-xl-8,.col-xl-9,.nav-link{
	float:left
}
.container,.container-fluid,.navbar-collapse,.navbar-nav,.row,aside,footer,main,section{
	display:block
}
.modal.fade .modal-dialog{
	-ms-transform:translate(0,-25%)
}
.modal.show .modal-dialog{
	-ms-transform:translate(0,0)
}
.custom-select{
	background-image:none;
	padding-right:.75rem
}
.img-fluid[src$=".svg"]{
	width:100%
}
.table-responsive{
	min-height:0
}
.progress{
	display:block
}
.progress-bar{
	float:left;
	font-size:12px;
	line-height:20px;
	width:0;
	text-align:center;
	height:100%
}
.d-flex,.d-lg-flex,.d-md-flex,.d-print-flex,.d-sm-flex,.d-xl-flex{
	display:block!important
}
.d-inline-flex,.d-lg-inline-flex,.d-md-inline-flex,.d-print-inline-flex,.d-sm-inline-flex,.d-xl-inline-flex{
	display:inline-block!important
}
.flex-row .div{
	display:inline-block
}
.flex-row-reverse{
	text-align:right
}
.flex-row-reverse .div{
	display:inline-block;
	float:right
}
.justify-content-start div{
	display:inline-block
}
.justify-content-end{
	text-align:right
}
.justify-content-end div{
	display:inline-block;
	margin-right:0;
	float:right
}
.justify-content-center{
	text-align:center
}
.justify-content-center div{
	display:inline-block;
	margin-right:auto;
	margin-left:auto;
	text-align:center
}
.justify-content-between{
	text-justify:distribute-all-lines
}
.justify-content-between div{
	display:inline-block;
	margin-right:auto;
	margin-left:auto;
	text-align:justify
}
.justify-content-around{
	text-align:justify;
	text-justify:distribute-all-lines
}
.justify-content-around div{
	display:inline-block;
	margin-right:auto;
	margin-left:auto;
	text-align:justify
}
[class^=justify-content-] div{
	display:inline-block
}
.carousel-control-next,.carousel-control-prev,.carousel-indicators{
	display:none
}
.btn-link.disabled,.btn-link:disabled{
	text-decoration:none
}
.modal-header .close{
	margin:-48px -16px -16px auto
}
.btn-group,.btn-group-vertical{
	display:inline;
	vertical-align:baseline
}
.btn-group .btn+.btn,.btn-group .btn+.btn-group,.btn-group .btn-group+.btn,.btn-group .btn-group+.btn-group,.btn-group-vertical .btn+.btn,.btn-group-vertical .btn+.btn-group,.btn-group-vertical .btn-group+.btn,.btn-group-vertical .btn-group+.btn-group{
	margin-left:-5px
}
.navbar-expand .navbar-collapse,.navbar-expand-lg .navbar-collapse,.navbar-expand-md .navbar-collapse,.navbar-expand-sm .navbar-collapse,.navbar-expand-xl .navbar-collapse{
	display:block!important
}
.nav,.navbar,.navbar>.container,.navbar>.container-fluid{
	display:block
}
.nav-link{
	padding:8px 16px
}
.navbar-brand{
	padding-top:5px;
	padding-bottom:5px;
	margin-right:16px;
	font-size:20px;
	float:left
}
.navbar:not(.navbar-expand-sm):not(.navbar-expand-md):not(.navbar-expand-lg):not(.navbar-expand-xl):not(.navbar-expand) .nav-link{
	float:none
}
.form-group>.is-valid{
	border-color:#28a745!important
}
.form-group>.is-invalid{
	border-color:#dc3545!important
}
.custom-control-input.is-valid~.valid-feedback,.custom-file-input.is-invalid~.invalid-feedback,.custom-select.is-invalid~.invalid-feedback,.custom-select.is-valid~.valid-feedback,.form-check-input.is-invalid~.invalid-feedback,.form-check-input.is-valid~.valid-feedback,.form-control-file.is-invalid~.invalid-feedback,.form-control-file.is-valid~.valid-feedback,.form-control.is-invalid~.invalid-feedback,.form-control.is-valid~.valid-feedback{
	display:block
}
.form-group>.is-valid:focus{
	box-shadow:0 0 0 .2rem rgba(40,167,69,.25)
}
.form-group>.is-invalid:focus{
	box-shadow:0 0 0 .2rem rgba(220,53,69,.25)
}